import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
  min-height: calc(100vh - 96px);
  background: ${white};
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    min-height: auto;
  }
  
  .form {
    width: 100%;
    
    @media ${device.tablet} {
      margin: 0 auto;
      max-width: 664px;
      padding: 0;
    }
    @media ${device.desktopLG} {
      max-width: 408px;
    }
    @media ${device.desktopLG} {
      max-width: 500px;
    }
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 96px);
  }

  h1, h2, h3 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  input {
    height: 48px;
    border-radius: 8px;
    width: 100%;
  }

  label {
    margin-bottom: 4px;
  }

  .back {
    display: initial;
    @media (min-width: 1601px) {
      display: block;
    }
  }

  button[type="submit"] {
    height: 48px;
    width: 100%;
    max-width: 100%;
    font-weight: 700;
    text-transform: uppercase !important;
    &:disabled {
      background: ${grayscale['200']};
      color: ${grayscale['300']} !important;
      text-transform: uppercase !important;
    }
  }

  .form-label-check {
    &:before {
      top: 34px;
      @media ${device.tablet} {
        top: 8px;
      }
      @media ${device.desktopLG} {
        top: 17px;
      }
      @media ${device.desktopXXXL} {
        top: 2px;
      }
    }
  }

  .mt-mobile {
    margin-top: 14px;
  }

  .form-input-check {
    &:checked ~ label {   
    &::after {
      top: 37px;  
        @media ${device.tablet} {
          top: 11px;
        } 
        @media ${device.desktopLG} {
          top: 20px;
        }
        @media ${device.desktopXL} {
          top: 20px;
        }
        @media ${device.desktopXXXL} {
          top: 5px;
        }  
      }
    }
  }

  .input-error {
    label, p {
      color: #F56A50;
    }
    input {
      background-color: #FEF0ED;
    }
  }
`
export const Image = styled.img`
  width: 104px;
  height: auto;
`

export const QRCode = styled.img`
  width: 268px;
  height:  268px;
`
